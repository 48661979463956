import axios, { AxiosResponse } from "axios";
import { history } from "../..";
import { toast } from "react-toastify";
import { IUser, IUserFormValues } from "../models/user";
import { ICompetitionEnvelope, ICompetition } from "../models/competition";
import { IRanking } from "../models/ranking";
import { IParticipantEnvelope, IParticipantDetail, IParticipant, IHighlightRanking, IEternalRanking } from "../models/participant";
import { ITeam } from "../models/team";
import { Competition as competitionForm } from "../models/competitionForm";
import IGalleryImage from "../common/util/imageGallery";

axios.defaults.baseURL = process.env.REACT_APP_API_URL;

axios.interceptors.request.use(
    (config) => {
        const token = window.localStorage.getItem("jwt");
        if (token) config.headers.Authorization = `Bearer ${token}`;
        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);

axios.interceptors.response.use(undefined, (error) => {
    if (error.message === "Network Error" && !error.response) {
        toast.error("Network error - make sure API is running!");
    }
    const { status, data, config } = error.response;

    if (status === 404) {
        history.push("/notfound");
    }
    if (status === 401) {
        console.log(error.response);
    }
    if (
        status === 400 &&
        config.method === "get" &&
        data.errors.hasOwnProperty("id")
    ) {
        history.push("/notfound");
    }
    if (status === 500) {
        toast.error("Server error - check the terminal for more info!");
    }

    throw error.response;
});

const responseBody = (response: AxiosResponse) => response.data;

const requests = {
    get: (url: string) => axios.get(url).then(responseBody),
    post: (url: string, body: {}) =>
        axios.post(url, body).then(responseBody),
    put: (url: string, body: {}) =>
        axios.put(url, body).then(responseBody),
    del: (url: string) => axios.delete(url).then(responseBody),
    postForm: (url: string, file: Blob) => {
        let formData = new FormData();
        formData.append("File", file);

        return axios
            .post(url, formData, {
                headers: { "Content-type": "multipart/form-data" },
            })
            .then(responseBody);
    },
};

const User = {
    current: (): Promise<IUser> => requests.get("/user"),
    login: (user: IUserFormValues): Promise<IUser> =>
        requests.post("/user/login", user),
    register: (user: IUserFormValues): Promise<IUser> =>
        requests.post("/user/register", user),
};

const Competition = {
    availableData: (): Promise<number[]> => requests.get('competition/availabledata'),
    list: (params: URLSearchParams): Promise<ICompetitionEnvelope> => {
        return axios
            .get("/competition", { params: params })
            .then(responseBody)
    },
    details: (id: string): Promise<ICompetition> => requests.get(`/competition/${id}`),
    ranking: (year: string): Promise<IRanking> => requests.get(`/ranking/ranking/?year=${year}`),
    highlights: (year: string): Promise<IHighlightRanking[]> => requests.get(`/ranking/highlights/?year=${year}`),
    create: (competition: competitionForm) => requests.post('/competition/create', competition),
    eternalranking: (): Promise<IEternalRanking[]> => requests.get(`/ranking/eternalranking`)
}

const Participant = {
    list: (params: URLSearchParams): Promise<IParticipantEnvelope> => {
        return axios
            .get("/participant", { params: params })
            .then(responseBody)
    },
    details: (id: string): Promise<IParticipantDetail> => requests.get(`/participant/${id}`),
    edit: (participant: Partial<IParticipant>) => requests.put(`/participant/${participant.id}`, participant)
}

const Team = {
    details: (id: number): Promise<ITeam> => requests.get(`/team/${id}`),
}

const Image = {
    garage: (): Promise<IGalleryImage[]> => requests.get('/image/garage'),
    tournament: (): Promise<IGalleryImage[]> => requests.get('/image/tournament'),
    exhibition: (): Promise<IGalleryImage[]> => requests.get('/image/exhibition')
}

// const Profiles = {
//   get: (userName: string): Promise<IProfile> =>
//     requests.get(`/profiles/${userName}`),
//   uploadPhoto: (photo: Blob): Promise<IPhoto> =>
//     requests.postForm(`/photos`, photo),
//   setMainPhoto: (id: string) => requests.post(`/photos/${id}/setMain`, {}),
//   deletePhoto: (id: string) => requests.del(`/photos/${id}`),
//   edit: (profile: IProfileFormValues) => requests.put("/profiles", profile),
//   follow: (userName: string) =>
//     requests.post(`/profiles/${userName}/follow`, {}),
//   unfollow: (userName: string) => requests.del(`/profiles/${userName}/follow`),
//   listFollowings: (userName: string, predicate: string) =>
//     requests.get(`/profiles/${userName}/follow?predicate=${predicate}`),
//   listActivities: (userName: string, predicate: string) =>
//     requests.get(`/profiles/${userName}/activities?predicate=${predicate}`),
// };

const apiTypes = {
    User,
    Competition,
    Participant,
    Team,
    Image
};

export default apiTypes;

import React from 'react'
import { Header, Segment } from 'semantic-ui-react';

export const SingleLeague = () => {
    return (
        <Segment>
            <Header as="h2">Einzel Liga</Header>
            Auf Reaktion der vielen Anfragen möchten wir eine Einzel Liga starten.<br />
            Die Liga verkörpert eine einzigartige Gelegenheit, dein Darts-Spiel auf ein höheres Niveau zu bringen.
            Unser Konzept für die Liga ist klar: Es handelt sich um eine Trainingsliga, in der jeder Spieltag die gleiche Anzahl an Legs bietet, um sicherzustellen,
            dass du jede Woche genug Spielzeit und Gelegenheit zum Üben hast. Unsere Startgebühr sorgt dafür,
            dass ein gewisser Wettbewerbsdruck vorhanden ist und deine Konzentration immer auf höchstem Niveau bleibt.<br /><br />

            In dieser Liga zählt jedes Leg, da die Rangliste nach der Leg-Differenz erstellt wird. Das bedeutet,
            dass jedes Spiel wichtig ist und du über längere Distanzen trainiert wirst.
            Mit 9 Spielern pro Liga und 8 Spieltagen, die jeweils innerhalb von zwei Wochen gespielt werden müssen, bieten wir dir eine spannende Saison über 16 Wochen.<br /><br />

            Unsere Spielstätte, die Cafe Bar Garage, bietet die perfekte Atmosphäre für ein aufregendes Darts-Erlebnis,
            unterstützt von 2K-Software. In der ersten Liga spielst du 12 Legs (501 D.O.),
            während die Anzahl der Legs in anderen Ligen je nach Spielstärke variiert.<br /><br />

            Die Liga fördert Fairness und Wettbewerb, und die besten Spieler haben die Möglichkeit aufzusteigen.
            Die Ligazusammensetzung erfolgt nach Spielstärke, um für spannende und ausgeglichene Spiele zu sorgen.<br /><br />

            Mit einem Startgeld von nur 25 € pro Person und attraktiven Auszahlungen für die besten Spieler - 1. Platz: 75 €, 2. Platz: 60 €, 3. Platz: 50 € -
            ist die Teilnahme erschwinglich und belohnend.<br /><br />

            Bitte beachte, dass nur diejenigen spielberechtigt sind, die bis zum Anmeldeschluss die 25 € überwiesen haben.
            Außerdem gehen 40 € an den Verein, um die 2K-Gebühren und die Ausstattung der Spielorte zu decken.<br /><br />

            Die 1. Saison der Finnigan's Harp Einzel Liga hat begonnen. Insgesamt 27 Teilnehmer spielen in drei, nach Spielstärke gestaffelten, Ligen ihre Position aus.<br />
            Spieltage, Ergebnisse und Statistiken findest du in der 2k Software:<br />
            <a href="https://2k-dart-software.com/frontend/events/1/event/9586/participants">Premier League</a><br />
            <a href="https://2k-dart-software.com/frontend/events/1/event/10186/participants">Challenger League</a><br />
            <a href="https://2k-dart-software.com/frontend/events/1/event/10187/participants">Rookie League</a>
        </Segment>
    );
}
import UserStore from "./userStore";
import { createContext } from "react";
import { configure } from "mobx";
import CommonStore from "./commonStore";
import ModalStore from "./modalStore";
import CompetitionStore from "./competitionStore";
import ParticipantStore from "./participantStore";
import TeamStore from "./teamStore";

configure({ enforceActions: "always" });

export class RootStore { 
    commonStore: CommonStore;
    userStore: UserStore;
    modalStore: ModalStore;
    competitionStore: CompetitionStore;
    participantStore: ParticipantStore;
    teamStore: TeamStore;

    constructor()  {
        this.commonStore = new CommonStore(this);
        this.userStore = new UserStore(this);
        this.modalStore =new ModalStore(this);
        this.competitionStore = new CompetitionStore(this);
        this.participantStore = new ParticipantStore(this);
        this.teamStore = new TeamStore(this);
    }
}

export const RootStoreContext = createContext(new RootStore());